import { Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Post } from "../../types/Post"
import Tags from "../Tags"
import "./index.scss"

function readingTime(text: string) {
  const wpm = 225
  const words = text.trim().split(/\s+/).length
  return Math.ceil(words / wpm)
}

const PostDetail = ({ title, date, content, tags }: Post) => {
  const rt = readingTime(content)

  return (
    <article
      className="postWrapper"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header>
        <Link to="/">
          <h1 itemProp="headline" className="title">{`← ${parse(title)}`}</h1>
        </Link>

        <div className="meta">
          <Tags tags={tags} />
          <div>{`Published ${date} by anzi`}</div>
          <div>{rt} min Read —</div>
        </div>
      </header>

      {!!content && (
        <section itemProp="articleBody" className="content">
          {parse(content)}
        </section>
      )}
    </article>
  )
}

export default PostDetail
