import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostDetail from "../components/PostDetail"
import PostNav from "../components/PostDetail/PostNav"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  return (
    <Layout footer={<PostNav previous={previous} next={next} />}>
      <Seo title={post.title} description={post.excerpt} />
      <PostDetail {...post} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      tags {
        nodes {
          name
          slug
          uri
          count
          id
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      excerpt
      date(formatString: "MMMM DD, YYYY")
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      excerpt
      date(formatString: "MMMM DD, YYYY")
    }
  }
`
