import { Link } from "gatsby"
import React from "react"
import parse from "html-react-parser"
import "./index.scss"

interface PostSummary {
  title: string
  uri: string
  excerpt: string
  date: string
}

interface Props {
  previous?: PostSummary
  next?: PostSummary
}

const PostNav = ({ previous, next }: Props) => {
  const item = (
    direction: "left" | "right",
    { uri, title, date, excerpt }: PostSummary
  ) => {
    return (
      <li className="item">
        <Link to={uri} rel="prev">
          <div className="title">
            {direction == "left" && "← "}
            {parse(title)}
            {direction == "right" && " →"}
          </div>
          <div className="date">{date}</div>
          <div className="excerpt">{parse(excerpt)}</div>
        </Link>
      </li>
    )
  }

  return (
    <nav className="postNav">
      <ul>
        {previous && item("left", previous)}
        {next && item("right", next)}
      </ul>
    </nav>
  )
}

export default PostNav
